.faq-contact {
    background-color: $faq-contact-bg;
    font-family: $font-family-base;
    color: $brand-copy;

    .container {
        padding: 0 10px 70px 10px;

        h3 {
            font-size: 20px;
            font-weight: 700;
            line-height: 29px;
            margin-bottom: 40px;
        }

        .contact-content.contact-bg {
            background-color: #EAB454;
            color: #251646;

            hr {
                border-bottom: 1px solid #D38933;
            }

            a {
                color: #251646;
                border: none;
                text-decoration: none;
            }
        }

        .contact-content {
            background-color: $brand-app;
            color: #fff;
            font-size: 18px;
            border-radius: 10px;
            padding: 60px;
            max-width: 500px;
            margin: auto;

            hr {
                width: 100%;
                border: 0;
                height: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                margin: 50px 0;
            }

            span {
                font-weight: 600;
                font-size: 18px;
                width: 100%;
            }

            .link-tel {
                font-size: 20px;
                text-decoration: none;
                border-bottom: 1px solid #fff;
            }

            a {
                text-decoration: underline;
                color: #fff;
            }

            img {
                border-radius: 50%;
                max-width: 100%;
                width: 120px;
                display: block;
                margin: auto;
            }

            svg {
                width: 60px;
                height: 60px;
                border-radius: 0;
                margin: 0 auto;
                display: block;

                path {
                    fill: $faq-contact-icon;
                }
            }

            .margin-bottom {
                margin-bottom: 50px;
            }
        }
    }
}

.project-VR .faq-contact {
    padding-top: 70px;
}

@media only screen and (min-width : 370px) {

    .faq-contact {
        .container {
            padding: 0px 10% 70px 10%;

            .contact-content {
                padding: 60px 30px;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .faq-contact {
        .container {
            padding: 0px 0 70px 0;

            h3 {
                font-size: 32px;
                font-weight: 700;
                line-height: 46px;
            }

            .contact-content {
                span {
                    font-size: 20px;
                }

                .link-tel {
                    font-size: 22px;
                }
            }
        }
    }
}

@include media-breakpoint-up (md) {
    .faq-contact {
        .container {
            padding: 0px 0 100px 0;

            .contact-content {
                padding: 60px;

                span {
                    font-size: 18px;
                }

                .size {
                    font-size: 20px;
                }
            }
        }
    }
}

@include media-breakpoint-up (lg) {
    .faq-contact {
        .container {
            .contact-content {
                padding: 60px 30px;
            }
        }
    }
}