@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

//overide Bootstrap variables
@import "variables_diff.scss";

//include all of Bootstrap
//@import "~bootstrap/scss/bootstrap";

// Required Bootstrap
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "~bootstrap/scss/mixins";

//Optional Bootstrap
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/print";

// libs styles
@import "../../../libs/v2/styles/base.scss";
@import "../../../libs/v2/styles/bobframe_5.scss";
@import "../../../libs/v2/styles/fonts.scss";
@import "../../../libs/v2/styles/form.scss";
@import "../../../libs/v2/styles/links.scss";
@import "../../../libs/v2/styles/helper.scss";
@import "../../../libs/v2/styles/icons.scss";
@import "../../../libs/v2/styles/scroll_top.scss";
@import "../../../libs/v2/styles/spinner.scss";

@import "../../../libs/v2/studypages/styles/mixins";
@import "../../../libs/v2/studypages/styles/accordion";
@import "../../../libs/v2/studypages/styles/animation";
@import "../../../libs/v2/studypages/styles/base";
@import "../../../libs/v2/studypages/styles/button";
@import "../../../libs/v2/studypages/styles/carussel";
@import "../../../libs/v2/studypages/styles/custom_form";
@import "../../../libs/v2/studypages/styles/custom_frames";
@import "../../../libs/v2/studypages/styles/faq_contact";
@import "../../../libs/v2/studypages/styles/font_gotham";
@import "../../../libs/v2/studypages/styles/font_ideal_sans";
@import "../../../libs/v2/studypages/styles/font_jost";
@import "../../../libs/v2/studypages/styles/footer";
@import "../../../libs/v2/studypages/styles/nav2";
@import "../../../libs/v2/studypages/styles/passage_devices";
@import "../../../libs/v2/studypages/styles/passage";
@import "../../../libs/v2/studypages/styles/screenshot_frame";
@import "../../../libs/v2/studypages/styles/stripe_card";
@import "../../../libs/v2/studypages/styles/table";
@import "../../../libs/v2/studypages/styles/passage_study_over";

// individual styles
@import "hero";

/* when study is over, then make sure to uncomment this line in order to have the used styles for it */
// @import "passage_study_over";
