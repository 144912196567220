#screenshot_frame {
    display: none;
    overflow: hidden;
    position: relative;
    padding-left: calc(100vw - 100%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;

    &.bob-fading-in {
        position: fixed;
        top: -1;
        left: 0;
    }
}

.open-screenshots {
    background-color: $brand-sec;
    width: 100%;
    min-height: 100%;
    z-index: 2000;

    button {
        padding: 0;
        border: none;
        background: none;
    }

    .close-screenshots {
        margin-left: auto;
        margin-right: 40px;
        margin-top: 40px;

        img {
            width: 28px;
            height: 28px;
        }
    }

    .screenshot-contents {
        padding: 40px;

        object {
            margin-top: 40px;
            max-width: 300px;
            height: 599px;
        }
    }
}