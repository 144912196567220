// white button
// default styles for our buttons
// here you can add styles that should apply to all buttons
.bob-style {
    @include button-variant($hero-btn, rgb(145, 145, 145), $hero-btn, $brand-heading-dark);
    width: 80%;
    border: none;
    margin: 3px; //this is needed tp teh background shadow does not get cut off on active
    border-radius: 40px;
    font-family: 'Jost',
        sans-serif;
    font-size: 17px;
    line-height: 1.5;
    display: block;
}

.jumbotron {
    .bob-style {
       @include button-variant($hero-btn, rgb(145, 145, 145), $hero-btn, $brand-heading-dark);
        margin-bottom: 30px;
        border-radius: 40px;
        padding: 20px;
        margin-left: auto;
        margin-right: auto;
    }
}

.video {
    .bob-style {
       @include button-variant($hero-btn, rgb(145, 145, 145), $hero-btn, $brand-heading-dark);
        margin-bottom: 30px;
        border-radius: 40px;
        max-width: 250px;
        padding: 15px;
    }
}

.paragraph-carousel {
    .bob-style {
        @include button-variant($passage-button-bg, rgb(145, 145, 145), $passage-button-bg, $passage-button-label);
        max-width: 260px;
        padding: 10px 0px;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
    }
}

.form-contact {
    .bob-style {
        @include button-variant($passage-button-bg, rgb(145, 145, 145), $passage-button-bg, $passage-button-label);
        max-width: 260px;
        height: 50px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}

@include media-breakpoint-up(sm) {

    .jumbotron,
    .passage-image {
        .bob-style {
            font-size: 24px;
        }
    }
}

@include media-breakpoint-up (md) {
    .jumbotron {
        .bob-style {
            margin-left: 0;
        }
    }

    .paragraph-carousel {
        .bob-style {
            margin-top: 20px;
            margin-right: 0;
        }
    }

    .form-contact {
        .bob-style {
            margin-top: 40px;
            margin-right: 0;
        }
    }
}

@include media-breakpoint-up(lg) {

    .jumbotron {
        .bob-style {
            width: 60%;
        }
    }
}