.table-anchor {
    margin-top: -60px;
}

.table {
    padding-top: 100px;
    margin: 0 0 -40px 0;

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 28px;
    }

    h2, h3 {
        color: $brand-copy;
        font-weight: 700;
        margin-bottom: 30px;
    }

    p span {
        color: $brand-heading-dark;
        font-weight: 500;
    }

    .table-text-top {
        margin-bottom: 40px;
    }

    .table-text-bot {
        font-size: 15px;
    }

    .table-col {
        max-width: 650px;
        margin: 0 auto;
    }

    .table-col-right {
        margin-top: 40px;
    }
}

@include media-breakpoint-up(md) {
    .table {
        padding-top: 90px;
        margin: 0 0 0 0;
    
        h3 {
            font-size: 30px;
        }

        .table-col {
            padding: 0 70px;
        }

        .table-col-right {
            margin-top: 50px;
        }
    }   
}


@include media-breakpoint-up(lg) {
    .table {

        h2 {
            font-size: 38px;
        }
    
        h3 {
            font-size: 30px;
        }

        .table-col {
            max-width: none;
            padding: 0 15px;
            margin: 0;
        }

        .table-col-right {
            margin: 0 0 0 100px;
        }
    }   
}


@include media-breakpoint-up(xl) {
    .table {

        h2 {
            font-size: 42px;
        }
    
        h3 {
            font-size: 35px;
        }
    }   
}